import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { FaSistrix } from "react-icons/fa";
import Polkhero from "../components/hero/Polkhero";

function Polk() {
  return (
    <Layout>
      <Polkhero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>POLK COUNTY</h3>
            <p>
              Polk County is a leading contributor to the state’s economy and
              politics. Citrus, cattle, agriculture, and the phosphate industry
              still play vital roles in the local economy, along with an
              increase in tourist revenue in recent years. The county’s location
              between both the Tampa and Orlando metropolitan areas has aided in
              the development and growth of the area. Residents and visitors
              alike are drawn to the unique character of the county’s numerous
              heritage sites and cultural venues, stunning natural landscapes,
              and many outdoor activities, making Polk the heart of central
              Florida.
            </p>
            <p>
              Polk County is known as water skiing, citrus capital and bass
              fishing capital of the world.”
            </p>
            <p>
              Polk County is home to one public university, one state college,
              and four private universities. One Fortune 500 company, Publix
              Super Markets, has headquarters in the county.
            </p>
            <p>
              Polk County is home to professional baseball and basketball teams
              and boasts a rich history of collegiate sports competition at a
              number of its institutions of higher learning, including perennial
              NCAA Division II National Championship contender and titleholder
              (in multiple sports), Florida Southern College.
            </p>
            <p>
              It is also home to Sheriff Grady Judd, who has become a local
              celebrity for his lay it on the line way of addressing crime
              fighting and law breakers.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 724,777 (2019 census)
              </li>
              <li>
                <FaSistrix /> 89th Most Populous County in Florida
              </li>
              <li>
                <FaSistrix /> Founded: February 8, 1861
              </li>
              <li>
                <FaSistrix /> County Seat: Bartow
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Polk County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Lakeland
              </li>
              <li>
                <FaSistrix /> Plant City
              </li>
              <li>
                <FaSistrix /> Winter Haven
              </li>
              <li>
                <FaSistrix /> Haines City
              </li>
              <li>
                <FaSistrix /> Auburndale
              </li>
              <li>
                <FaSistrix /> Polk City
              </li>
              <li>
                <FaSistrix /> Davenport
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/6b65b478dc6167c51e3d92dad8731224/70dd7/polkmap.jpg"
              target="blank"
            >
              <StaticImage src="../images/polkmap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Polk;
